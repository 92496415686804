import React from 'react';
import { SiteMetadata } from '../components';
import { Layout } from '../layouts/Layout';

export default (props) => {
  return (
    <Layout>
      <SiteMetadata
        title="Subscribe to our streaming updates"
        description="Subscribe to our weekly updates to be notified when new theatre streamings are made available!"
        path={props.location.pathname}
      />
      <iframe
        title="Subscribe to our streaming updates"
        className="container mt-8 md:w-1/2"
        src="/subscribe.html"
        frameBorder="0"
        onMouseWheel=""
        width="100%"
        height="550"
      ></iframe>
    </Layout>
  );
};
